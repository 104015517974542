#salesheets-details-container {
  h3.title-page {
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  ul.licenses-list {
    padding-left: 28px;
    li div {
      line-height: 24px;
    }
  }
}