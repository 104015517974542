#film-details-container {
  .page-container {
    .page-content {
      padding: 0;
      background: transparent;
      margin: 24px auto;
      border-radius: 0;
      box-shadow: none;

      .details-container .item {
        h3.title-page {
          font-size: 14px;
          font-weight: 400;
          margin-top: 12px;
          margin-bottom: 12px;
        }
    
        ul.licenses-list {
          padding-left: 28px;
          li div {
            line-height: 24px;
          }
        }
      }
    }
  
    .page-content-footer {
      margin-right: 24px;
    }
  }
}