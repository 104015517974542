@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import './mixins.scss';

* {
  -webkit-backface-visibility:hidden !important;
}

html {
  font-size: 62.5% !important; /* vì html font size mặc định là 16px -> nên 16px * 62.5% = 10px = 1 rem */
  box-sizing: border-box;
}


body {
  margin: 0;
  padding: 0;
  background: #F1F3F6;
  font-size: 14px;
  height: 24px;
  font-weight: 400;
}

a {
  text-decoration: none;
  color: #009B40;
}

.loading-screen {
  width: 100vw;
  height: 100vh;

  @include flex;
  @include justify-content(center);
  @include align-items(center);
}
 
.ant-btn {
  padding: 6px 12px 6px 12px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  height: auto !important;
  @include border-radius(2px);

  span + .anticon {
    margin-right: 0;
    margin-left: 10px;
  }
}

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.ant-layout-header  {
  padding: 0;
  background-color: #005670;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 999;

  .logo {
    padding: 0 10px;
    img {
      width: 180px;
      height: 40px;
    }

    span {
      margin-left: 10px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      padding: 4px 7px;
      background-color: #fff;
      color: #1E88E5;
      border-radius: 11px;
    }
  }

  .account-info {
    @include flex;
    .avatar {
      width: 32px;
      margin-left: 10px;
      @include flex;

      img {
        width: 100%;
        border-radius: 50%;
      }
    }
    .info { 
      margin: auto 0;
      padding: 10px;
      line-height: 16px;
      font-size: 12px;
      color: #212121;

      .fullname {
        font-weight: 600;
      }

      .info-dropdown {
        button {
          width: 100%;
        }
      }
    }

    .ant-badge {
      margin: auto 10px;
      .anticon {
        width: 40px;
        display: initial;
        
        svg {
          height: 24px;
        }
      }
    }
  }
}

.header-info-dropdown {
  button {
    width: 100%;
    text-align: left;
  }
}

.site-layout {
  .Content {
    margin: 20px;
    &.Small {
      max-width: 760px;
      margin: 20px auto;
    }
  }
}

.ant-layout-header.site-header {
  background-color: #fff;
  @include flex;
  @include justify-content(space-between);
}

.form-container {
  .ant-form-item {
    margin-bottom: 16px;
    .ant-form-item-label {
      min-width: 156px;
      text-align: left;
    }
  }

  .form-header {
    .title {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      position: relative;
      color: #005670;

      &:before {
        content: '';
        width: 4px;
        height: 100%;
        background-color: #005670;
        position: absolute;
        top: 0;
        left: -16px;
      }
    }
  }

  .form-content {
    margin-top: 24px;

    .ant-form-item-row {
      .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content {
        @include flex;
      }
    }

    

    .add-fields {
      .ant-btn-dashed.ant-btn-block {
        width: auto;
        margin-top: 0;
      }
    }
  
    .btn-remove-section {
      margin-bottom: 16px;
      .ant-btn {
        padding: 4px 40px;
      }
    }
  
    .ant-btn-dashed.ant-btn-block {
      margin-bottom: 16px;
      @include flex;
      @include justify-content(center);
      @include align-items(center);
  
      &.btn-remove {
        border-color: $danger-color;
        color: $danger-color;
      }
    }
  }

  .form-footer {
    margin-top: 24px;
    @include flex;
    @include justify-content(flex-end);

    button, a {
      margin-left: 24px;
      &:first-child {
        margin-left: 0;
      }
    }

    a > button {
      &:first-child {
        margin-right: 0;
      }
    }
  }

  .ant-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  
  .ant-row .ant-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
    
    @media (max-width: 767px) {
      flex: 0;
    }
  }

  .fields-inline {
    @include flex;
    @include justify-content(space-between);

    .ant-form-item {
      width: 50%;

      &:last-child {
        margin-left: 24px;
      }
    }

    .ant-picker {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .ant-row {
      flex-flow: column;
    }
  }
}

.ant-form-item-explain {
  height: auto;
  min-height: 0 !important;

  .ant-form-item-explain-error {
    font-size: 10px;
    min-height: 12px;
    position: absolute;
    // bottom: -15px;
    left: 0;
  }
}

.ant-form-item-with-help.password-input {
  .ant-form-item-explain {
    div, .ant-form-item-explain-error, .ant-form-item-explain-success {
      font-size: 10px;
      min-height: 12px;
      position: absolute;
      top: 32px;
      left: 0;
    }
  }
}

.page-container {
  padding: 16px;
    
  .page-header {
    margin-top: 16px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    @include flex;
    @include justify-content(space-between);
    @include align-items(center);

    .ant-breadcrumb {
      span {
        text-transform: uppercase;
      }
    }
  }

  .page-header-action-left {
    @include flex;
    @include align-items(center);
    @include justify-content(flex-start);
  }
  
  .page-header-actions {
    @include flex;

    button:not(:last-child), a:not(:last-child) {
      margin-right: 12px;
    }
  }

  .page-content {
    padding: 16px;
    background: #FFFFFF;
    margin: 24px auto;
    @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.08));
    @include border-radius(2px);


    &.list {
      width: 100%;
    }

    .page-content-header {
      margin-bottom: 24px;
      @include flex;
      @include justify-content(space-between);
      @include align-items(center);

      h2.title-page {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        position: relative;
        color: $primary-color;

        &:before {
          content: '';
          width: 4px;
          height: 100%;
          background-color: $primary-color;
          position: absolute;
          top: 0;
          left: -16px;
        }
      }

      .page-content-header-actions {
        @include flex;
        
        button {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }

    .details-container {
      .flex-2-item {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        border-bottom: 1px solid #e0e0e0;
        flex-wrap: wrap;

        @include flex;
        @include justify-content(space-between);

        &:last-child {
          border-bottom: none;
        }

        .flex-item {
          width: 50%;

          label {
            min-width: 152px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 600;
            white-space: pre-line;
            max-width: 152px;
            margin-right: 24px;
            display: inline-block;
          }
    
          span.value {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;

            &.status {
              display: block;
              width: 120px;
              padding-top: 4px;
              padding-bottom: 4px;
              font-weight: 600;
              font-size: 12px;
              font-style: normal;
              line-height: 16px;
              text-align: center;
              text-transform: capitalize;

              @include border-radius(2px);

              &.rejected, &.cancelled, &.disabled {
                color: #f44336;
                background-color: rgba(244, 67, 54, 0.12);
              }

              &.approved, &.completed, &.enabled, &.open, &.active {
                color: #00c853;
                background-color: rgba(0, 200, 83, 0.12);
              }

              &.pending, &.refunded, &.closed {
                color: #ad6800;
                background-color: #ffe58f;
              }

              &.created, &.inactive {
                color: #888888;
                background-color: rgba(136, 136, 136, 0.12);
              }
              
              &.acknowledged, &.upcoming {
                color: #1E88E5;
                background-color: rgba(30,136,229,0.12);
              }

              &.attending {
                color: #FFC400;
                background-color: rgba(255,196,0,0.12);
              }
            }
          }

          @media (max-width: 767px) {
            width: 100%;
          }
        }
      }


      .item {
        padding-top: 7.5px;
        padding-bottom: 7.5px;
        border-bottom: 1px solid #e0e0e0;
        @include flex;
        @include align-items(center);

        &:last-child {
          border-bottom: none;
        }

        label {
          min-width: 152px;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          white-space: pre-line;
          max-width: 152px;
          margin-right: 24px;
        }
  
        span.value {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;

          &.status {
            display: block;
            width: 120px;
            padding-top: 4px;
            padding-bottom: 4px;
            font-weight: 600;
            font-size: 12px;
            font-style: normal;
            line-height: 16px;
            text-align: center;
            text-transform: capitalize;

            @include border-radius(2px);

            &.rejected, &.cancelled, &.disabled {
              color: #f44336;
              background-color: rgba(244, 67, 54, 0.12);
            }

            &.approved, &.completed, &.enabled, &.open, &.active {
              color: #00c853;
              background-color: rgba(0, 200, 83, 0.12);
            }

            &.pending, &.refunded, &.closed {
              color: #ad6800;
              background-color: #ffe58f;
            }

            &.created, &.inactive {
              color: #888888;
              background-color: rgba(136, 136, 136, 0.12);
            }
            
            &.acknowledged, &.upcoming {
              color: #1E88E5;
              background-color: rgba(30,136,229,0.12);
            }

            &.attending {
              color: #FFC400;
              background-color: rgba(255,196,0,0.12);
            }
          }
        }
      }
    }
  }

  .page-content-footer {
    margin-top: 24px;
    @include flex;
    @include align-items(center);
    @include justify-content(flex-end);

    button, a {
      margin-right: 12px;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  @media (max-width: 1199px) {
    .page-content {
      width: 100%;
    }
  }

  @media (max-width: 479px) {
    .page-content .details-container .item {
      @include align-items(flex-start);
      flex-direction: column;

      label {
        margin-bottom: 16px !important;
      }
    }
  }
}

.ant-modal-content {
  padding: 0 !important;
  .ant-modal-body {
    padding: 16px;
    .form-header {
      .title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        position: relative;
        color: $primary-color;

        &:before {
          content: '';
          width: 4px;
          height: 100%;
          background-color: $primary-color;
          position: absolute;
          top: 0;
          left: -16px;
        }
      }
    }

    .form-content {
      .ant-form-item {
        flex-flow: column;
      }
    }

    h2.title-page {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      position: relative;
      color: $primary-color;
      margin-bottom: 24px;

      &:before {
        content: '';
        width: 4px;
        height: 100%;
        background-color: $primary-color;
        position: absolute;
        top: 0;
        left: -16px;
      }
    }
  }
}

.ant-modal-confirm-confirm {
  .ant-modal-content {
    min-width: 375px;
    padding: 40px 24px;

    .ant-modal-body {
      padding: 16px;
    }

    .ant-modal-confirm-body {
      @include flex;

      .ant-modal-confirm-content {
        margin-top: 0;
        margin-left: 12px;
      }
    }

    .ant-modal-confirm-body span.anticon.anticon-exclamation-circle {
      color: $danger-color;
    }

    .ant-modal-confirm-btns {
      margin-top: 24px;
      width: 100%;
      @include flex;
      @include justify-content(center);

      button {
        min-width: 100px;

        &:first-child {
          background-color: #fff;
          color: $primary-color;
          border: 1px solid $primary-color;
        }

        &.ant-btn-primary {
          margin-left: 16px;
          background-color: $primary-color;
          color: #fff;
          border: 1px solid $primary-color;
        }
      }
    }
  }
}

table {
  thead.ant-table-thead th {
    padding: 10px 16px;
    border-top: 1px solid $border-color;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    flex: none;
    background: #fff !important;

    &:first-child {
      border-right: 1px solid $border-color;
    }

    &:last-child {
      border-left: 1px solid $border-color;
    }

    span.ant-table-filter-column-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      flex: none;
      padding: 0;
    }

    .ant-table-filter-column {
      padding: 17px 16px;
      @include flex;
      @include align-items(center);
    }

    .ant-table-filter-trigger-container {
      position: unset;
      margin-left: 5px;

      &:hover {
        background: #fff;
      }
    }

    .ant-table-filter-trigger {
      text-align: center;
      @include border-radius(2px);

      .anticon {
        position: unset;
        color: $primary-color;
        -webkit-transform: none;
        transform: none;
      }
    }
  }
  
  td {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    background: #fff;
  }

  tbody.ant-table-tbody {
    tr td {
      padding: 12px 16px;

      &:first-child {
        border-right: 1px solid #F4F4F4;
      }

      &:last-child {
        border-left: 1px solid $border-color;
      }

      span.action {
        @include flex;
        @include justify-content(center);
        @include align-items(center);

        & > button, & > a {
          padding-left: 8px;
          padding-right: 8px;

          & > button {
            padding: 6px 0;
          }
        }

        .ant-dropdown-button {
          button {
            padding: 0 6px 0 6px;
            border: none;
            background-color: transparent;
            @include transform(rotate(90deg));

            &:first-child {
              display: none;
            }

            span.anticon.anticon-ellipsis {
              height: 16px;
            }
          }
        }
      }

      span.table-status {
        text-transform: capitalize;
        padding-top: 4px;
        padding-bottom: 4px;
        width: 120px;
        display: block;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        @include border-radius(2px);
      }
    
      span.table-status.active {
        color: #00C853;
        background-color: rgba(0,200,83,0.12);
      }

      span.table-status.inactive {
        color: #888888;
        background-color: rgba(136, 136, 136, 0.12);
      }

      .foreign-currency-td {
        @include flex;
        @include align-items(center);

        & > div {
          margin-right: 12px;

          & > div {
            @include flex;
          }
        }
      }

      .sms-transaction-details {
        .details-type {
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          text-transform: capitalize;
        }

        .details-account-no {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }

    tr:last-child {
      td {
        padding-bottom: 12px;
      }
    }

    .btn-download {
      position: relative;
      min-width: 100%;
      min-height: 32px;
      @include justify-content(center);
      @include align-items(center);

      &> div {
        margin-left: 0px;
      }

      svg {
        width: 16px;
        height: 30px;
      }
    }
  }
}

.table-action-menu {
  .ant-dropdown-menu {
    @include border-radius(4px);
    
    .btn-danger {
      border: none;
      background-color: transparent;
      color: $danger-color !important;
    }
  }
}

.ant-input, .ant-select-selector, .ant-picker {
  background-color: #fff;
  font-size: 14px !important;
  border: 1px solid #E0E0E0;
  border-radius: 2px !important;
  min-height: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum","tnum";
  position: relative;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0,0,0,.85);
  background-image: none;
  transition: all .3s;
}

input.ant-input::-webkit-input-placeholder {
  color: #d9d9d9;
}

input.ant-input:-ms-input-placeholder {
  color: #d9d9d9;
}

input.ant-input::placeholder {
  color: #d9d9d9;
}

.ant-input:not[textarea], .ant-select-selector, .ant-picker {
  height: 32px !important;
}

.ant-input {
  min-height: 32px;

  &:focus {
    border-color: #4096ff;
    box-shadow: 0 0 0 2px rgb(5 145 255 / 10%);
    border-inline-end-width: 1px;
    outline: 0;
  }
}

.filter-form-container {
  .ant-input {
    min-height: 38px;
  }
  
  .form-container {
    @include flex;
    @include align-items(center);
    flex-wrap: wrap;

    .form-content {
      margin-top: 0;
      flex-wrap: wrap;
      @include flex;

      .ant-picker {
        width: 100%;
        min-height: 38px;
      }

      .ant-form-item, .ant-select {
        min-width: 200px;
        margin-right: 16px !important;

        &:first-child { 
          margin-left: 0 !important;
        }
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .ant-select-multiple .ant-select-selector {
        height: 38px;
        padding: 4px 8px;
        overflow-y: auto;
      }
    }

    .form-footer {
      margin-top: 0;
      margin-left: 0; 

      button {
        margin-left: 0 !important; 
        margin-bottom: 16px;
        margin-right: 24px;

        &:last-child  {
          margin-right: 0;
        }
      }
    }
  }

  &.expand-filter {
    .form-content {
      justify-content: space-between;
      width: 100%;

      .ant-form-item {
        flex-grow: 1;
      }
    }
    .form-footer {
      width: 100%;

      button {
        margin-bottom: 0;
      }
    }
  }

  @media (max-width: 530px) {
    .form-container {
      width: 100%;
      .form-content {
        width: 100%;
        .ant-form-item {
          width: 100%;
          min-width: 100%;
          margin-right: 0 !important;
        }
      }

      .form-footer {
        width: 100%;
      }
    }
  }
}

.btn-table-action-primary {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #1E88E5;
  border: none;
  background: none;
  @include box-shadow(none);

  @include flex;

  & > div {
    margin-left: 8px;
  }
}

.btn-table-action-danger {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: #F44336;

  @include flex;

  & > div {
    margin-left: 8px;
  }
}

.pagination-container {
  @include flex;
  @include justify-content(flex-end);
  @include align-items(center);
}

.ant-form-item.documents {
  .ant-upload.ant-upload-drag {
    background: #fff;
    border: 1px dashed #E0E0E0;
    @include border-radius(2px);
    height: 112px;
    padding: 0 24px;

    .ant-upload {
      @include flex;
      @include align-items(center);
      @include justify-content(center);
    }

    .ant-upload-text {
      font-size: 12px;
      line-height: 16px;
      color: #888888;
      padding: 0 12px;
    }

    .ant-upload-hint {
      @include flex;
      @include justify-content(center);
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #1E88E5;
      // margin: 16px 0 0 0;

      svg {
        margin: 2px 0 0 6px;
        width: 12px;
      }
    }
  }
}

.ant-upload-wrapper {
  width: 100%;
  .ant-upload-drag .ant-upload {
    padding: 0;
  }
}

.documents {
  width: 100%;
  .ant-upload-wrapper {
    width: 100%;
  }
}