.license-form-container {
  .rights-list-section {
    & > h2 {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .ant-form-item-label {
    min-width: 200px;
    width: 200px
  }

  .right-item-section {
    margin-left: 156px;
  }

  .ant-select-disabled {
    .ant-select-selector {
      background: transparent !important;
      color: #212121 !important;
    }

    &.ant-select-multiple .ant-select-selection-item {
      color: #212121 !important;
    }
  }
}
