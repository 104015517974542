.film-form-container {
  .form-container .ant-form-item .ant-form-item-label {
    min-width: 180px;

    > label {
      display: block;
      max-width: 180px;
      white-space: pre-line;
      height: auto;
    }
  }

  .territories-licenses-section {
    h4 {
      font-size: 14px;
      font-weight: 400;
    }
    .territories-licenses-question-list {
      margin-left: 180px;

      @media (max-width: 575px) {
        margin-left: 0;
      }
    }
  }
}