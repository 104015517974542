.films-container {
  table {
    tbody tr td {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
          margin-bottom: 5px;
        }
      }
    }
  }
}