@import '../../../mixins.scss';

.btn-danger {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  background-color: $danger-color;
  border: 1px solid $danger-color;
  color: #fff !important;
  @include border-radius(2px);

  &:hover, &.ant-btn:focus {
    background-color: $danger-color;
    border: 1px solid $danger-color;
    opacity: 0.8;
  }

  svg {
    margin-left: 12px;
  }

  &.secondary {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
  }
}

.btn-primary {
  &:hover {
    color: #fff !important;
    border-color: $primary-color !important;
  }
}