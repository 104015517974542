@import '../../../mixins.scss';

.btn-primary {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  background-color: $primary-color;
  border: 1px solid $primary-color;
  color: #fff;
  @include border-radius(4px);

  svg {
    margin-left: 10px;
  }

  &:hover {
    background-color: $primary-color !important;
    border: 1px solid $primary-color;
    opacity: 0.8;
  }

  &.secondary-color {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;

    &:hover {
      background-color: $secondary-color !important;
      border: 1px solid $secondary-color;
      opacity: 0.8;
    }
  }

  svg {
    margin-left: 12px;
  }

  &[disabled] {
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
}