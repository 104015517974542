.login-form-container {
  .ant-input-affix-wrapper {
    padding: 0px 11px !important;
  }

  .ant-input {
    height: 48px !important;
    padding: 4px 11px;
    border-radius: 8px !important;
  }

  .forgot-password-section {
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .FormFooter {
    margin-top: 32px;
    button {
      font-size: 14px;
      line-height: 22px;
      padding: 10px 12px;
    }
  }

  .ant-form-item-with-help.password-input .ant-form-item-explain div, .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-error, .ant-form-item-with-help.password-input .ant-form-item-explain .ant-form-item-explain-success {
    top: 50px;
  }
}