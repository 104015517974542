.films-components-list-container {
  .page-content .page-content-header {
    margin-bottom: 0 !important;
  }

  .more-details-section {
    display: block;

    @media (max-width: 479px) {
      display: none;
    }
  }
}

.table-action-menu .btn-table-more-details {
  display: none;

  @media (max-width: 480px) {
    display: block;
  }
}