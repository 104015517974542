@import '../../../mixins.scss';

.btn-secondary {
  @include flex;
  @include justify-content(center);
  @include align-items(center);
  border: 1px solid $primary-color !important;
  color: $primary-color;
  @include border-radius(2px);

  &:hover {
    border-color: $primary-color !important;
    color: $primary-color !important;
    box-shadow: none;
    opacity: 0.8;
  }

  &.secondary-color {
    border: 1px solid $secondary-color !important;
    color: $secondary-color;
  }

  &.danger-color {
    border: 1px solid $danger-color !important;
    color: $danger-color;
  }

  svg {
    margin-left: 12px;
  }

  &.btn-icon {
    svg {
      margin-left: 0;
    } 
  }
}

.ant-btn-text:hover, .ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: $primary-color !important;
}