@import '../../mixins.scss';

#salesheets-customer-details-container {
  .site-header {
    padding: 12px 0;

    img {
      margin-left: 12px;
    }
  }

  .page-container {
    padding: 0 !important;
    
    & > header {
      margin-bottom: 36px;
    }
  }

  .details-section {
    h2.title-page {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
      position: relative;
      color: #009B40;

      &::before {
        content: "";
        width: 4px;
        height: 100%;
        background-color: #009B40;
        position: absolute;
        top: 0;
        left: -16px;
      }
    }

    .item {
      padding-top: 7.5px;
      padding-bottom: 7.5px;
      border-bottom: 1px solid #e0e0e0;
      @include flex;
      @include align-items(center);

      &:last-child {
        border-bottom: none;
      }

      label {
        min-width: 152px;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        white-space: pre-line;
        max-width: 152px;
        margin-right: 24px;
      }

      span.value {
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;

        h3.title-page {
          font-size: 12px;
          text-transform: uppercase;
          line-height: 14px;
        }

        .licenses-list {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}