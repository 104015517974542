@import '../../mixins.scss';

.film-details-shared {
  .banner-section {
    position: relative;
    max-height: 600px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      width: 100%;  
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
      z-index: 0;
    }
  }

  .details-section {
    width: 100%;
    position: relative;

    .details-content-wrap {
      width: 100%;
      
      .wrapper {
        padding: 0 16px;
        @include flex;
        @include justify-content(center);
      }
    }

    .film-poster {
      width: 33rem;
      margin-right: 24px;

      img {
        width: 100%;
      }
    }

    .page-details-content {
      width: 100%;
      padding-bottom: 50px;
    }
    
    .page-content-wrapper {
      background-color: #fff;
      padding: 16px;
      @include border-radius(8px);
    }

    .film-title {
      h2 {
        font-style: normal;
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        color: $primary-text;
        margin-top: 0;
        margin-bottom: 8px;
      }
    }
  }

  .details-container {
    margin-top: 24px;
  }

  @media (max-width: 767px) {
    .details-section {
      display: block;
      margin-top: 0;
      padding: 0;
      position: relative;

      .film-poster {
        width: 100%;
        height: auto;
        margin-top: 24px;
        flex-wrap: wrap;

        @include flex;
        @include align-items(flex-start);
        
        img {
          width: 22rem;
          height: auto;
          margin-right: 24px;
        }

        & > .film-title {
          display: block;
        }
      }

      .film-title {
        margin-top: 0;
        margin-bottom: 24px;

        h2 {
          color: $primary-text;
          line-height: 24px;
          font-size: 22px;
        }
      }

      .details-content-wrap {
        .wrapper {
          display: block;
        }
      }

      .page-content-wrapper {
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 767px) {
    .details-section {
      .film-poster {
        display: block;

        img {
          width: 18rem;
        }
      }

      .film-title h2 {
        color: $primary-text;
      }
    } 
  }

  @media (max-width: 480px) {
    .details-section {
      .details-content-wrap {
        margin-top: -46px;
      }

      .film-poster {
        width: 100%;
        height: auto;
        margin-top: 0;

        img {
          width: 9rem;
        }
      }

      .film-details-shared .details-section .film-title h2 {
        color: #212121;
        line-height: 42px;
        font-size: 26px;
      }
    }
  }
}