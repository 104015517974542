@import '../../../mixins.scss';

.enter-password-container {
  padding: 0 24px;
  height: 100vh;
  
  @include flex;
  @include align-items(center);

  .page-content {
    width: 479px;
  }
}