@import '../../../mixins.scss';

.notifications-container {
  width: 360px;
  cursor: pointer;
  margin: -12px -12px;
  font-size: 12px;
  .header {
    border-bottom: 1px solid #F4F4F4;
    padding: 12px;
    .title {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-transform: uppercase;
      color: #212121;
      @include flex;
      @include align-items(center);
      @include justify-content(space-between);
      svg {
        height: 17px;
        width: 17px;
        color: #E0E0E0;
      }
      .ant-btn {
        padding: 0;
      }
    }
    .actions {
      padding-top: 8px;

      .actions-top {
        @include flex;
        @include align-items(center);
        @include justify-content(space-between);
      }

      .actions-bottom {
        @include flex;
        @include align-items(center);
        @include justify-content(space-between);
      }

      .ant-divider {
        margin: 5px 0;
      }

      .ant-btn {
        padding: 0 5px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        &.view-unread-only {
          color: #1E88E5;
        }
        &.mark-all-as-read {
          color: #888888;
        }
      }

      .multiple-delete-notifications {
        border: 1px solid #888;
        margin-left: 12px;
      }
    }
  }
  .items {
    padding: 8px 0;
    max-height: 50dvh;
    overflow-y: auto;
    
    .infinite-scroll-component  {
      max-height: calc(100vh - 170px);
    }

    .ant-checkbox-group {
      @include flex;
      @include align-items(center);
      @include justify-content(flex-start);
      flex-wrap: nowrap;
      width: 100%;

      label.ant-checkbox-wrapper {
        padding-left: 12px;
      }
    }
  }
  .notification {
    width: 100%;
    position: relative;

    .notification-wrap {
      padding: 8px 12px;
      border-bottom: 1px solid #EEEEEE;
      @include flex;
      @include align-items(center);
    }

    .delete-notification-button {
      display: none;
      content: '';
      position: absolute;
      top: 8px;
      right: 12px;

      .wrap {
        gap: 4px;
        @include flex;
      }

      button {
        padding: 3px;
        width: 20px;
        height: 20px !important;
        @include border-radius(15px);
        @include flex;
        @include align-items(center);
        @include justify-content(center);
        @include box-shadow(0px 2px 9px 0px rgba(0,0,0,0.75));

        svg {
          margin-left: 0;
          width: 16px;
          height: 16px;
          fill: #fff;
        }

        &.done-button {
          background: $primary-color;
          border-color: $primary-color;
        }

        &.delete-button {
          background: #898989;
          border-color: #898989;
        }

        &:hover {
          width: 24px;
          height: 24px !important;
        }
      }
    }

    &:last-child {
      border: none;
    }
    &.read {
      background: #F4F4F4;
    }
    &:hover {
      background: #e4f1fc;
      border-color: #fff;

      .delete-notification-button {
        display: block;
      }
    }
    .icon {
      width: 24px;
      padding-right: 8px;
      div.svg-icon {
        div {
          height: 24px;
        }
      }
    }
    .main {
      width: 100%;
      @include flex;
      @include justify-content(space-between);
    }
    .title-date {
      width: 100%;
      @include flex;
      @include justify-content(space-between);
      .title {
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        padding-right: 8px;
      }
      .datetime {
        font-size: 12px;
        line-height: 16px;
        color: #888888;
      }
    }
    .content-read {
      @include flex;
      @include justify-content(center);
      @include align-items(center);
      width: 20px;
      height: 14px;
      .read {
        line-height: 16px;
      }
    }
  }
}