@import '../../../../mixins.scss';

.licenses-filter-form-container {
  &.filter-form-container .form-container .form-content {
    @include justify-content(flex-start);    

    .ant-form-item {
      max-width: 246px;
    }

    .ant-select {
      margin-right: 0 !important;
    }
  }
}