@import '../../mixins.scss';

.login-container {
  .login-form-container {
    width: 520px;
    margin: 110px auto;
    padding: 24px;
    background: #fff;
    @include border-radius(8px);    
  }

  .logo-section {
    text-align: center;
  }

  h2.title {
    font-size: 24px;
    line-height: 32px;
    color: $primary-color;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
