@import '../../../mixins.scss';

.upload-image-section {
  .image-uploader {
    max-width: 100%;
    height: 100%;
    min-height: 110px;

    &.ant-upload-disabled {
      border: none !important;
    }
  }

  .ant-form-item-control-input {
    min-height: auto;
  }

  .is-preview-image {
    .ant-form-item-control-input-content {
      position: relative;

      & > span {
        min-width: 100%;
      }
    }
    .ant-upload-list-picture-card {
      min-width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      
      .ant-upload-list-picture-card-container {
        min-width: 100%;
        margin: 0;
        height: 100%;
      }

      .ant-upload-list-item-list-type-picture-card {
        padding: 0;
      }
    }
  }

  .view-image {
    width: 100%;
    min-height: 110px;
  }
}
